import { Text } from '@workos-inc/component-library';
import { InputType } from '@workos-inc/ui-kit/dist/interfaces/input';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsHibob: ArrayStepsConfig = {
  providerLabel: 'Hibob',
  type: 'Array',
  steps: [
    {
      name: 'Create a Service User in Hibob',
      // eslint-disable-next-line react/display-name
      render: ({ appName, onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in to your Hibob instance as an admin user and navigate to
            settings, then select the "Integrations" tab.
          </Text>

          <Img
            priority
            height={354}
            src="/directory-sync/hibob/v1/hibob-1.png"
            width={754}
          />

          <Text>
            There will be a tile called Service Users, click on that then, press
            on the New Service user Button.
          </Text>

          <Text>
            You will be prompted to enter a Name and Display Name for the
            service user. Give the Service User a descriptive name and display
            name such as <strong>{appName} SCIM User</strong> and select "Done".
          </Text>

          <Img
            height={725}
            src="/directory-sync/hibob/v1/hibob-2.png"
            width={1167}
          />

          <Confirm
            label="I've created a Hibob service user with Name and Display Name."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload Hibob Service User ID and Token',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange,
        onNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            An <strong> ID </strong> and <strong> Token </strong>
            will appear - make sure to save these somewhere secure should you
            need to access them again.
          </Text>

          <Img
            priority
            height={514}
            src="/directory-sync/hibob/v1/hibob-3.png"
            width={733}
          />

          <Text>Upload the Service User ID and Token below:</Text>

          <Card>
            <Form
              disabled={
                !directory?.bob_service_user_id || !directory?.bob_api_token
              }
              isLoading={isLoading}
              isUpdate={
                !!(errors?.bob_service_user_id || errors?.bob_api_token)
              }
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                error={errors?.bob_service_user_id}
                label="1. Service User ID"
                name="bob_service_user_id"
                onChange={onInputChange}
                placeholder="SERVICE-387"
                value={directory?.bob_service_user_id}
              />

              <TextField
                error={errors?.bob_api_token}
                label="2. Service User API Token"
                name="bob_api_token"
                onChange={onInputChange}
                placeholder=""
                type={InputType.Password}
                value={directory?.bob_api_token}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: MapAttributesStep,
    },
  ],
};
