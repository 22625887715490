import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { ManualMetadataStep } from 'components/sso/steps/saml/manual-metadata-step';
import { Article, Ol, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useState } from 'react';
import { useFeature } from 'utils/feature-flags';

export const JumpCloudCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Application</Title>

    <Img
      priority
      height={1684}
      src="/sso/jump-cloud/v1/jump-cloud-1.png"
      width={3224}
    />

    <Text>
      From the JumpCloud Admin Console, select “SSO” on the navigation to the
      left. Then select the green “+” sign to create a new application.
    </Text>

    <Img
      priority
      height={1636}
      src="/sso/jump-cloud/v1/jump-cloud-2.png"
      width={2234}
    />

    <Text>In the subsequent pop-up window, select “Custom SAML App”.</Text>

    <Img
      height={1638}
      src="/sso/jump-cloud/v1/jump-cloud-3-edited.png"
      width={2208}
    />

    <Text>
      Under "Display Label", give it a descriptive name to easily identify it
      later such as {appName}-{connection?.name}.
    </Text>

    <Confirm
      label="I’ve finished naming the SAML Application"
      onClick={onNextStep}
    />
  </Article>
);

export const JumpCloudSubmitUrlEntityId: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Submit ACS URL and Entity ID</Title>

    <Img
      priority
      height={1210}
      src="/sso/jump-cloud/v1/jump-cloud-4-edited.png"
      width={2224}
    />

    <Text>
      Copy the Entity ID into the "IdP Entity ID" and "SP Entity ID" fields.
    </Text>

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>Copy the ACS URL into the "ACS URL" field.</Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Img height={712} src="/sso/jump-cloud/v1/jump-cloud-5.png" width={1490} />

    <Text>IMPORTANT: Be sure to check the “Sign Assertion” box.</Text>

    <Confirm
      label="I’ve configured the Assertion Consumer Service (ACS) URL and
          Entity ID."
      onClick={onNextStep}
    />
  </Article>
);

export const JumpCloudConfigureAttributeStatements: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Configure Attribute Statements</Title>

    <Img
      priority
      height={802}
      src="/sso/jump-cloud/v1/jump-cloud-6.png"
      width={1506}
    />

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img height={198} src="/sso/jump-cloud/v1/jump-cloud-11.png" width={531} />

    <Text>
      In the “User Attribute Mapping” section, click on the "add attribute"
      button to begin adding new user attributes. Add in the above Attribute
      Statements.
    </Text>

    <Img height={125} src="/sso/jump-cloud/v1/jump-cloud-7.png" width={397} />

    <Img height={568} src="/sso/jump-cloud/v1/jump-cloud-8.png" width={1302} />

    <Text>
      Once you’ve finished, select “activate” and then click on the “continue”
      button on the subsequent pop-up window.
    </Text>

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “activate” and “continue“."
      onClick={onNextStep}
    />
  </Article>
);

export const JumpCloudUploadMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => {
  const {
    connection,
    isLoading,
    errors,
    onNextStep,
    onInputChange,
    onFileInput,
    validationErrors,
    setConnectionUpdatedFields,
    connectionUpdatedFields,
  } = connectionStepProps;

  const [selectedConfiguration, setSelectedConfiguration] = useState('dynamic');

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb-4">
          Step 4: Upload Identity Provider Metadata
        </Title>

        <Img
          priority
          height={702}
          src="/sso/jump-cloud/v1/jump-cloud-9.png"
          width={1239}
        />

        <Text>Select your newly created Application.</Text>

        <Img
          priority
          height={329}
          src="/sso/jump-cloud/v1/jump-cloud-10.png"
          width={744}
        />

        <Text>
          Download the “IDP Certificate” by clicking on the dropdown menu next
          to “IDP Certificate Valid” and selecting “Download certificate”.
          Upload the X.509 Certificate below when prompted.
        </Text>

        <Text>
          Open the "Single Sign-On Configuration" section and copy the “IDP URL”
          to use as the Identity Provider Single Sign-On URL below.
        </Text>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              !connection?.saml_entity_id ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={
              !!(
                errors?.saml_idp_url ||
                errors?.saml_entity_id ||
                errors?.saml_x509_certs
              )
            }
            onSubmit={onNextStep}
          >
            <TextField
              autoFocus={true}
              error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
              label="1. Identity Provider Single Sign-On URL"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://sso.jumpcloud.com/..."
              value={connection?.saml_idp_url}
            />

            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  const onXmlInput = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event?.target?.files) {
      const file = event?.target?.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setConnectionUpdatedFields({
            saml_idp_metadata_xml: reader.result?.toString(),
          });
        };

        reader.readAsText(file);
      }
    }
  };

  return (
    <Article>
      <Title className="mb-4">Step 4: Upload Identity Provider Metadata</Title>

      <Text>
        The final step for implementing SAML SSO requires sharing your identity
        provider's metadata with the application.
      </Text>

      {selectedConfiguration === 'dynamic' && (
        <>
          <Text as="h2" size="large" weight="medium">
            URL Metadata Configuration
          </Text>

          <Ol className="my-4">
            <li>Click on the “SSO” tab of the SAML app you just created.</li>

            <li>Be sure to check "Declare Redirect Endpoint".</li>

            <Img
              height={1066}
              src="/sso/jump-cloud/v1/jump-cloud-12.png"
              width={2560}
            />

            <li>
              Click the "Export Metadata" button under "JumpCloud Metadata".
            </li>
            <li>A file will be downloaded. Upload it below.</li>
          </Ol>

          <Img
            height={1766}
            src="/sso/jump-cloud/v1/jump-cloud-13.png"
            width={2560}
          />

          <Card>
            <Form
              disabled={!connectionUpdatedFields?.saml_idp_metadata_xml}
              isLoading={isLoading}
              isUpdate={!!errors?.saml_idp_metadata_xml}
              onSubmit={onNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <FileField
                accept=".xml"
                error={errors?.saml_idp_metadata_xml}
                filename="XML Metadata File"
                label="XML Metadata File"
                name="saml_idp_metadata_xml"
                onUpload={onXmlInput}
                value={connectionUpdatedFields?.saml_idp_metadata_xml}
              />
            </Form>
          </Card>
        </>
      )}

      {selectedConfiguration === 'manual' && (
        <ManualMetadataStep
          {...connectionStepProps}
          idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
          idpSsoUrlPlaceholder="https://sso.jumpcloud.com/..."
          onConfigurationTypeChange={setSelectedConfiguration}
        />
      )}
    </Article>
  );
};
