import { Row, space } from '@workos-inc/ui-kit';
import { Card } from 'components/card';
import { Value } from 'components/input/value';
import { Label } from 'components/label';
import { Spinner } from 'components/spinner';
import React from 'react';

type ReadOnlyInputProps = {
  label: string;
  value?: string;
};

export const ReadOnlyInput: React.FC<Readonly<ReadOnlyInputProps>> = ({
  label,
  value,
}) => (
  <Card>
    <Label>{label}</Label>
    <Row marginTop={space.spacing(2)}>
      <Value>{value ? value : <Spinner />}</Value>
    </Row>
  </Card>
);
