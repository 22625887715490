export const parseFile = async (file: File): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onloadend = () => resolve(reader.result?.toString());
    reader.onerror = (event) => {
      reject(event);
      reader.abort();
    };
  });
