import classnames from 'classnames';
import NextLink from 'next/link';
import React, { FC } from 'react';

export interface LinkProps {
  href: string;
  as?: string;
  appearance?: 'highlight';
  className?: string;
  newTab?: boolean;
  download?: string;
}

export const Link: FC<Readonly<LinkProps>> = ({
  href,
  as,
  children,
  appearance,
  className,
  newTab = false,
  download,
}) => {
  const classNames = classnames(
    'duration-200 transition ease-in-out',
    {
      'text-primary-darken hover:underline dark:text-primary':
        appearance === 'highlight',
    },
    className,
  );

  return (
    <NextLink as={as || href} href={href}>
      <a
        className={classNames}
        download={download}
        rel={newTab ? 'noreferrer noopener' : ''}
        target={newTab ? '_blank' : '_self'}
      >
        {children}
      </a>
    </NextLink>
  );
};
