import Color from 'color';

export const setPrimaryColorCssVariable = (primaryColor: string) => {
  if (!document) {
    // running from the server
    return;
  }

  const color = Color(primaryColor);

  document.documentElement.style.setProperty(
    '--color-primary-lightest',
    color.lighten(0.7).hex(),
  );
  document.documentElement.style.setProperty(
    '--color-primary-light',
    color.lighten(0.5).hex(),
  );
  document.documentElement.style.setProperty(
    '--color-primary-lighten',
    color.lighten(0.2).hex(),
  );
  document.documentElement.style.setProperty(
    '--color-primary-default',
    primaryColor,
  );
  document.documentElement.style.setProperty(
    '--color-primary-darken',
    color.darken(0.2).hex(),
  );
  document.documentElement.style.setProperty(
    '--color-primary-dark',
    color.darken(0.5).hex(),
  );
  document.documentElement.style.setProperty(
    '--color-primary-darkest',
    color.darken(0.7).hex(),
  );
};
