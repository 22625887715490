import { Colors, Input } from '@workos-inc/ui-kit';
import { InputType } from '@workos-inc/ui-kit/dist/interfaces/input';
import { Fieldset } from 'components/fieldset';
import { Error } from 'components/input/error';
import { Label } from 'components/label';
import { Error as ErrorInterface } from 'interfaces/errors';
import React, { ChangeEventHandler } from 'react';

type TextFieldProps = {
  autoFocus?: boolean;
  buttonText?: string;
  error?: ErrorInterface;
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: InputType;
  value?: string | null;
};

export const TextField: React.FC<Readonly<TextFieldProps>> = ({
  autoFocus,
  error,
  label,
  name,
  onChange,
  placeholder,
  value,
  type = InputType.Text,
}) => {
  const isError = error?.value === value;

  return (
    <Fieldset>
      <Label color={isError ? Colors.Red : undefined}>{label}</Label>
      <Input
        autoFocus={autoFocus}
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          borderColor: isError ? 'rgba(208, 61, 61, 0.5)' : Colors.MidGray,
        }}
        type={type}
        value={value || ''}
      />
      {isError && <Error>{error?.message}</Error>}
    </Fieldset>
  );
};
