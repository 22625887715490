import { Colors, Loading } from '@workos-inc/ui-kit';
import React, { FC, useContext } from 'react';
import styled, { keyframes, ThemeContext } from 'styled-components';

type SpinnerProps = {
  isCube?: boolean;
};

export const Spinner: FC<Readonly<SpinnerProps>> = ({ isCube }) => {
  const theme = useContext(ThemeContext);
  const spinnerColor = theme.primary;

  if (isCube) {
    return <SpinnerCube color={spinnerColor} />;
  }

  return <Loading color={spinnerColor} size={8} />;
};

type SpinnerCubeProps = {
  color: Colors;
};

const SpinnerCube: FC<Readonly<SpinnerCubeProps>> = ({ color }) => {
  const cubes = [0, 1, 3, 2].map((v) => (
    <Cube key={v} color={color} delay={0.3 * v} scale={90 * v} />
  ));

  return (
    <div className="flex justify-center items-center w-full h-full opacity-0 animate-[fadeIn_250ms_1s_forwards]">
      <div className="relative rotate-45 w-10 h-10">{cubes}</div>
    </div>
  );
};

const fold = keyframes`
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`;

export const Cube = styled.div<{
  scale: number;
  delay: number;
  color: string;
}>`
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1) rotateZ(${(p) => p.scale}deg);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${fold} 2.4s infinite linear both ${(p) => p.delay}s;
    transform-origin: 100% 100%;
    background-color: ${(p) => p.color};
  }
`;
