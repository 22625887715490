import { Button } from '@workos-inc/component-library';
import { Colors, IconFile, Row, space } from '@workos-inc/ui-kit';
import { Card } from 'components/card';
import { Label } from 'components/label';
import { Link } from 'components/link';
import { Spinner } from 'components/spinner';
import React, { useState } from 'react';

type DownloadInputProps = {
  filename: string;
  label: string;
  value?: string;
};

export const DownloadInput: React.FC<Readonly<DownloadInputProps>> = ({
  filename,
  label,
  value,
}) => {
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const handleDownload = (): void => {
    setTimeout(() => setDownloaded(false), 2000);

    setDownloaded(true);
  };

  return (
    <Card>
      <Label color={Colors.TonedMidGray}>{label}</Label>
      <Row
        alignItems="center"
        justifyContent="space-between"
        marginTop={space.spacing(2)}
      >
        {value ? (
          <>
            <Link appearance="highlight" download={filename} href={value}>
              <IconFile style={{ marginRight: space.spacing(1) }} />
              {filename}
            </Link>

            <Link download={filename} href={value}>
              <Button
                appearance={downloaded ? 'minimal' : undefined}
                onClick={handleDownload}
              >
                {downloaded ? 'Downloaded!' : 'Click to download'}
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Spinner />
            <Button disabled={true} onClick={() => null}>
              Click to download
            </Button>
          </>
        )}
      </Row>
    </Card>
  );
};
