import { Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export interface ManualMetadataStepProps extends ConnectionStepProps {
  onConfigurationTypeChange: (configurationType: 'dynamic') => void;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const ManualMetadataStep: React.VFC<
  Readonly<ManualMetadataStepProps>
> = ({
  connection,
  connectionUpdatedFields,
  setConnectionUpdatedFields,
  errors,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  onConfigurationTypeChange,
  validationErrors,
}) => (
  <div>
    <Text as="h2" size="large" weight="medium">
      Manual Configuration
    </Text>

    <Text>
      See your Identity Provider’s documentation on how to configure a Service
      Provider.
    </Text>

    <Card>
      <Form
        disabled={
          !connection?.saml_idp_url ||
          !connection?.saml_x509_certs ||
          !connection?.saml_entity_id ||
          validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
        }
        isLoading={isLoading}
        isUpdate={
          !!(
            errors?.saml_idp_url ||
            errors?.saml_entity_id ||
            errors?.saml_x509_certs
          )
        }
        onSubmit={onNextStep}
        secondaryAction={() => onConfigurationTypeChange('dynamic')}
        secondaryText="Configure Dynamically"
      >
        <TextField
          error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
          label="1. Identity Provider Single Sign-On URL"
          name="saml_idp_url"
          onChange={onInputChange}
          placeholder={idpSsoUrlPlaceholder}
          value={connection?.saml_idp_url}
        />

        <TextField
          label="2. Identity Provider Issuer"
          name="saml_idp_entity_id"
          onChange={(event) =>
            setConnectionUpdatedFields((fields) => ({
              ...fields,
              samlIdpEntityId: event.target.value,
            }))
          }
          placeholder={idpEntityIdPlaceholder}
          value={connectionUpdatedFields.samlIdpEntityId}
        />

        <FileField
          error={errors?.saml_x509_certs}
          filename="X.509 Certificate"
          label="3. X.509 Certificate"
          name="saml_x509_certs"
          onUpload={onFileInput}
          value={connection?.saml_x509_certs?.[0]}
        />
      </Form>
    </Card>
  </div>
);
