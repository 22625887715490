import { Text } from '@workos-inc/component-library';
import { Colors, Radius, space, Weight } from '@workos-inc/ui-kit';
import classNames from 'classnames';
import { ProviderLogo } from 'components/provider-logo';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React, { ReactElement } from 'react';
import { Check } from 'react-feather';
import styled, { css } from 'styled-components';

export interface ProviderBoxProps {
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  provider: ConnectionType | DirectoryType;
  label?: string;
  color?: string;
}

export const ProviderBox = ({
  active,
  label,
  provider,
  onClick,
  color = Colors.BrandPrimary,
}: ProviderBoxProps): ReactElement => (
  <StyledProviderBox
    active={active}
    color={color}
    hasLabel={Boolean(label)}
    onClick={onClick}
  >
    <ProviderLogo provider={provider} width="140px" />
    {label && <Text as="p">{label}</Text>}
    <CheckItem visible={active} />
  </StyledProviderBox>
);

interface CheckProps {
  visible: boolean;
}

const CheckItem = ({ visible }: CheckProps) => (
  <div
    className={classNames(
      'w-9 h-9 rounded-full absolute -top-3 -right-2 flex items-center justify-center transition-opacity bg-primary',
      {
        'opacity-0': !visible,
        'opacity-1': visible,
      },
    )}
  >
    <Check className="stroke-white" size={16} strokeWidth={3} />
  </div>
);

const StyledProviderBox = styled.button<{
  active?: boolean;
  color: string;
  hasLabel: boolean;
}>`
  appearance: none;
  border: 1px solid ${Colors.TonedLightGray};
  border-radius: ${Radius.Medium};
  height: 84px;
  width: 172px;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${Colors.White};

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }

  &:focus {
    outline: none;
    border: 1px solid ${(p) => p.color};
  }

  & > svg {
    height: ${(p) => (p.hasLabel ? '24px' : '32px')};
  }

  ${(p) =>
    p.active &&
    css`
      background-color: #f8faff;
      border-color: ${p.color} !important;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }`}

  & > span {
    font-weight: ${Weight.Regular};
    font-size: 15px;
    color: ${Colors.TonedMidGray};
    margin-top: ${space.spacing(2)};
  }
`;
