import { Text } from '@workos-inc/component-library';
import { InputType } from '@workos-inc/ui-kit/dist/interfaces/input';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { Link } from 'components/link';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsBreatheHr: ArrayStepsConfig = {
  providerLabel: 'Breathe HR',
  type: 'Array',
  steps: [
    {
      name: 'Generate API Key in Breathe HR',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            If you are an admin of your Breathe HR instance, log in and select
            the "Configure" option in the top menu of the Breathe HR console,
            then select "Settings" in the drop down menu.
          </Text>

          <Img
            priority
            height={375}
            src="/directory-sync/breathe-hr/v1/breathe-hr-1.png"
            width={2245}
          />

          <Text>Under "Integrations", select "API Setup".</Text>

          <Img
            priority
            height={1294}
            src="/directory-sync/breathe-hr/v1/breathe-hr-2.png"
            width={2235}
          />

          <Text>Select "Enable API".</Text>

          <Img
            height={842}
            src="/directory-sync/breathe-hr/v1/breathe-hr-3.png"
            width={2248}
          />

          <Text>
            Verify that you'd like to enable the API to access user information.
          </Text>

          <Img
            height={618}
            src="/directory-sync/breathe-hr/v1/breathe-hr-4.png"
            width={1006}
          />

          <Text>
            Save the generated production key. You'll upload this key in the
            next step.
          </Text>

          <Img
            height={1108}
            src="/directory-sync/breathe-hr/v1/breathe-hr-5.png"
            width={2252}
          />

          <Confirm
            label="I've generated my Breathe HR API key."
            onClick={onNextStep}
          />
        </>
      ),
    },
    {
      name: 'Upload Breathe HR API Key',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange,
        onNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>Upload the API key you generated in your last step.</Text>

          <Card>
            <Form
              disabled={!directory?.breatheHrApiKey}
              isLoading={isLoading}
              isUpdate={!!errors?.breatheHrApiKey}
              onSubmit={onNextStep}
            >
              <TextField
                autoFocus={true}
                error={errors?.breatheHrApiKey}
                label="1. Breathe HR API Key"
                name="breatheHrApiKey"
                onChange={onInputChange}
                placeholder=""
                type={InputType.Password}
                value={directory?.breatheHrApiKey}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            The full list of available attributes from Breathe HR can be found{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://developer.breathehr.com/examples#!/employees/GET_version_employees_json'
              }
            >
              in their API documentation.{' '}
            </Link>
            You'll need to input your generated API key to see the above
            documentation.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
