import { motion } from 'framer-motion';
import React, { FC } from 'react';

type ArticleProps = {
  className?: string;
};
export const Article: FC<Readonly<ArticleProps>> = ({
  children,
  className,
}) => (
  <motion.div
    animate={{ opacity: 1 }}
    className={className}
    initial={{ opacity: 0 }}
    transition={{ duration: 0.75 }}
  >
    {children}
  </motion.div>
);
